/* For Header.js */
.header {
    width: 100% !important;
    position: sticky !important;
    display: block !important;
    z-index: 500 !important;
    font-family: sans-serif !important;
    top: 0 !important;
    margin: 0 !important;
}

.row-header {
    height: 56px !important;
    background-color: #4A525A;
}

.logo {
    margin-top: -3px !important;
    width: 62px;
    height: 62px;
    margin-left: 28px !important;
}

.back-link {
    color: white;
    text-decoration: none !important;
    font-size: 18px !important;
    margin-right: 5px !important;
    float: right !important;
    margin-top: -48px !important;
    background-color: transparent !important;
    outline: transparent !important;
    border: transparent !important;
}

.back-link:hover {
    color: white !important;
}

.back-link:focus,
.back-link:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.page-header {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 26pt !important;
    color: #4A525A !important;
    text-align: center !important;
    background-color: white;
}

/* Styling for ReportsApp.js*/
.reports-area {
    margin: 0;
    font-family: sans-serif;
    font-size: 11pt;
    flex-shrink: 2;
}
.select-btn{
    max-height: 38px !important;
}
.card-count{
    color:#70757a;
    bottom: 0 !important;
    text-align: bottom !important;
    font-size: small;
}
.filters-offcanvas {
    background: rgba(255, 250, 250, 0.94);
    min-height: 35vh;
}

@media (max-width: 400px) and (min-width: 320px) {
    .card-list {
        min-height: 54vh;
        max-height: 54vh !important;
    }

    .reports-area {
        min-height: 70vh;
        max-height: 70vh !important;
    }
}

@media (max-width: 480px) and (min-width: 401px) {
    .card-list {
        min-height: 59vh;
        max-height: 59vh !important;
    }

    .reports-area {
        min-height: 72vh;
        max-height: 72vh !important;
    }
}

@media (max-width: 768px) and (min-width: 481px) {
    .card-list {
        min-height: 70vh;
        max-height: 70vh !important;
    }

    .reports-area {
        min-height: 74vh;
        max-height: 74vh !important;
    }
}

@media (max-width: 1024px) and (min-width: 769px) {
    .card-list {
        min-height: 70vh;
        max-height: 70vh !important;
    }

    .reports-area {
        min-height: 74vh;
        max-height: 74vh !important;
    }
}

@media (max-width: 1200px) and (min-width: 1025px) {
    .card-list {
        min-height: 63vh;
        max-height: 63vh !important;
    }

    .reports-area {
        min-height: 71vh;
        max-height: 71vh !important;
    }
}

@media (max-width: 1440px) and (min-width: 1201px) {
    .card-list {
        min-height: 71vh;
        max-height: 71vh !important;
    }

    .reports-area {
        min-height: 78vh;
        max-height: 78vh !important;
    }
}

@media (min-width: 1441px) {
    .card-list {
        min-height: 78vh;
        max-height: 78vh !important;
    }

    .reports-area {
        min-height: 82vh;
        max-height: 82vh !important;
    }
}

.action-btns-area {
    bottom: 0 !important;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    position: sticky !important;
}

/* Styling for ReportsArea.js*/

.reports-custom-toggle-button {
    background-color: transparent !important;
    color: black;
    min-width: 155px;
    outline: none !important;
    border: .7px solid lightgrey;
    border-radius: 0px !important;
    padding-left: 1px !important;
    height: 20px;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
}

.reports-custom-toggle-button:focus {
    background-color: transparent !important;
    color: black;
    width: 100%;
    outline: none !important;
    border: .7px solid lightgrey;
    border-radius: 0px !important;
    padding-left: 1px !important;
    height: 20px;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
}

.reports-custom-toggle-button:hover {
    background-color: transparent !important;
    color: black;
    width: 100%;
    outline: none !important;
    border: .7px solid lightgrey;
    border-radius: 0px !important;
    padding-left: 1px !important;
    height: 20px;
    padding-top: 0 !important;
    margin-bottom: 0 !important;
}

.filters-button {
    width: 100%;
    margin-left: 3px;
    font-family: sans-serif;
    font-size: 11pt !important;
    text-align: left !important;
}

.filters-button:hover,
.filters-button:focus {
    width: 100%;
    margin-left: 3px;
    font-family: sans-serif;
    font-size: 11pt !important;
    text-align: left !important;
    box-shadow: none !important;
}

.report-display {
    margin: 0;
}

.search-input-field {
    width: 100% !important;
    margin: 0 !important;
}

.card-list {
    margin-top: .5vh !important;
    overflow-y: scroll !important;
    overflow: hidden;
    border: .5px solid gray;
    padding-right: 0 !important;
    margin-right: 0;
}

.pdf-quick-view {
    overflow-y: scroll;
    max-height: 82vh !important;
}

.no-pdf {
    padding: auto !important;
    text-align: center !important;
    margin-top: 350px !important;
}

.info-label-col {
    padding-left: 0 !important;
}

.info-label {
    white-space: nowrap !important;
    color: #4A525A;
    font-weight: 550;
    font-size: 10pt;
}

.text-value {
    white-space: nowrap !important;
    font-size: 10pt !important;
    color: #70757a;
    line-height: 15px;
    margin-bottom: 0 !important;
}

.report-card {
    padding-bottom: 0 !important;
    margin: 0 !important;
    /* min-height: 110px; */
    max-height: 305px;
    width: 100% !important;
    padding-left: 12px !important;
    padding-right: 0 !important;
}
.totals-card{
    padding-bottom: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    padding-left: 12px !important;
    padding-right: 0 !important;
    position: sticky !important;
    z-index: 500 !important;
    top: 0 !important;
    border-bottom: 1px solid black;
}

.bottom-card-col {
    padding-left: 0 !important;
}

/*Might use this Might not: */
.mobile-view-link {
    position: absolute;
    bottom: 0 !important;
}

.desktop-view-btn {
    background: transparent !important;
    color: #0c6dfd;
    text-decoration: underline;
    position: absolute;
    bottom: 0 !important;
    cursor: pointer;
}

/* Styling for Filters.js*/
.filters-cols {
    margin-bottom: .7vh !important;
    padding-left: .5vh !important;
    padding-right: .5vh !important;
}

.date-picker {
    width: 100% !important;
    border: 0.5px solid lightgray !important;
    border-radius: 0px;
    background: transparent !important;
    min-height: 24px !important;
    max-height: 24px !important;
    font-size: 10pt !important;
    z-index: 9999;
}

.custom-toggle-button {
    background-color: transparent !important;
    color: black;
    width: 100%;
    outline: none !important;
    border: .7px solid lightgrey;
    border-radius: 0px !important;
    padding-left: 1px !important;
    height: 24px;
    padding-top: 0;
}

.custom-toggle-button:focus {
    background-color: transparent !important;
    color: black;
    width: 100%;
    outline: none !important;
    box-shadow: none !important;
    border: .7px solid lightgrey;
    padding-left: 1px !important;
}

.custom-toggle-button:hover {
    background-color: transparent !important;
    color: black;
    width: 100%;
    outline: none !important;
    box-shadow: none !important;
    border: .7px solid lightgrey;
    padding-left: 1px !important;
}

.toggle-icon {
    transform: rotate(90deg);
    float: right;
    margin-top: 3px;
    margin-right: -10px !important;
}

.toggle-label {
    min-width: 97% !important;
    max-width: 97% !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    float: left !important;
    text-align: left !important;
    font-family: sans-serif !important;
    font-size: 10pt;
}

/* Styling for ActionButtons.js*/
.action-cols {
    margin-bottom: .7vh !important;
    padding: .5px;
}

.buttons-row {
    margin-top: 1vh !important;
}

.action-btn {
    justify-content: center !important;
    text-align: center !important;
    width: 100% !important;
    font-size: 11pt;
    height: 100% !important;
}

/* For PDF quick view sizing*/
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

/* For Email Modal */
.submit-btn {
    background-color: #007AFF !important;
}

.submit-btn,
.cancel-btn {
    border-radius: 6px !important;
    width: 100%;
}

.cancel-btn {
    margin-top: 8px;
    margin-bottom: 0%;
}
.submit-btn-small,
.cancel-btn-small {
    border-radius: 6px !important;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 0%;
}

.report-document {
    padding: 0;
}

.btn-secondary {
    background-color: gray;
    border: gray;
}

.message-box {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    resize: none;
}

.message-box-row {
    height: 15vh;
    margin-top: 5px;
}
.message-box-row-small{
    height: 10vh;
    margin-top: 5px;
}

.email-button-col {
    justify-content: flex-end !important;
}

.delete-btn:focus {
    color: red;
    background-color: white;
    text-align: center;
    padding: 0;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.delete-btn:hover {
    color: blue;
    box-shadow: none;
    background-color: white;
    text-align: center;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.delete-btn {
    color: red;
    background-color: white;
    border: none;
    text-align: center;
    padding: 0;
    margin-bottom: 6px;
    margin-right: 1px;
}
.delete-btn-sm:focus {
    color: red;
    background-color: white;
    text-align: center;
    padding: 0;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 12px !important;
}

.delete-btn-sm:hover {
    color: blue;
    box-shadow: none;
    background-color: white;
    text-align: center;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 12px !important;
}

.delete-btn-sm {
    color: red;
    background-color: white;
    border: none;
    text-align: center;
    padding: 0;
    margin-bottom: 6px;
    margin-right: 1px;
    font-size: 12px !important;
}

.address-col {
    text-align: left !important;
    margin: 0;
    padding: 0;
}

.email-box-row {
    padding: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    border: .7px solid lightgray;
    border-radius: 6px;
    margin-bottom: 5px !important;
    height: 15vh;
    overflow-y: scroll;
    display: block !important;
}

.email-address-row {
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: .7px solid lightgray;
    max-height: 3.5vh !important;
    min-height: 3.5vh !important;
}
.email-address-row-sm{
    padding: 0 !important;
    margin: 0 !important;
    border-bottom: .7px solid lightgray;
    max-height: 3vh !important;
    min-height: 3vh !important;
}

.email-col{
    min-height: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}

@media (max-width: 1965px) and (min-width: 1200px) {
    .laptop-width {
       width: 33%; 
    }

    .pdf-quick-view {
        width: 67%;
    }

    /* .card-list{
        max-height: 76vh !important;
        min-height: 76vh !important;
    } */

}                                                   